@use "./../../scss/variables" as *;

.cta-container{
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;
    gap: 2rem;
    border-radius: 0 0 2rem 2rem;
    padding: 5%;
    border: none;

    background-image: url('../../assets/backgrounds/cta.svg');
    background-size: cover;
    background-position: top; 
    background-repeat: no-repeat; 

    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.20);


   
    h2{

        color: white;
        padding: 0 5%

    }

    .cta-buttons-container{
        display: flex;
        flex-direction: column;
        gap: 0.8rem;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        margin-top: 1rem;

        a{
            display: inline-flex;
            text-decoration: none; /* Optional: removes underline */
            justify-content: center;
            width: 100%;
            align-items: center;
        }

        button{
            min-width: 9rem;
            padding: 0.5rem 1rem;
            border-radius: 4rem;
            border: none;
            background-color: transparent;
            color: white;
            font-size: 14px;
            background-color: rgba(255, 255, 255, 0.207);
    
        }


        

        :nth-child(1){
            align-items: right;
            justify-content: right;

            button{
                background-color: $orange;

                &:hover{
                    background-color: $orangeDark;
                }
            }
           
        }

        


        a{
            width: 100%;
        }

       

    }

 
}

@media (min-width: 768px) {
    .cta-container{     
        border-radius: 0 0 5rem 5rem;
       .cta-buttons-container{
           flex-direction: row;
           a{
                min-width: 40%;
                max-width: 45%;
            }
          
       }
       
    }
   }

@media (min-width: 1024px) {
 .cta-container{
    h2{
        max-width: 700px;
    }
    
    
 }
}