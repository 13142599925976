.logos {
  width: 100%;
  overflow: hidden;
  padding: 2rem 0;
  position: relative;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    height: 100%;
    background: linear-gradient(to right, #f8f8f8 0%, rgba(248, 248, 248, 0) 100%);
    z-index: 2;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 100px;
    height: 100%;
    background: linear-gradient(to left, #f8f8f8 0%, rgba(248, 248, 248, 0) 100%);
    z-index: 2;
  }
  
  .logos-container {
    display: flex;
    align-items: center;
    animation: scroll 40s linear infinite;
    width: fit-content;
    gap: 8rem;
    padding: 0 2rem;

    .logos-set {
      display: flex;
      gap: 8rem;
    }

    img {
      height: 40px;
      max-width: 100px;
      object-fit: contain;
      opacity: 0.7;
      transition: opacity 0.3s ease;

      &:hover {
        opacity: 1;
      }
    }
  }
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-100% / 4));
  }
}

@media (max-width: 768px) {
  .logos {
    .logos-container {
      gap: 4rem;
      
      .logos-set {
        gap: 4rem;
      }
      
      img {
        height: 30px;
      }
    }
  }
}
