@use "./../../scss/mixins";

.benefits-container {
  padding: 10% 10% 10% 10%;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  border-radius: 0 0 2rem 2rem;
  position: relative;

  div.benefits-title {
    display: flex;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
    flex-direction: column;
    width: 100%;

    h2 {
      min-width: 50%;
      text-align: center;
      align-self: center;
      color: #333;
    }

    p {
      width: 100%;
      color: grey;
      text-align: center;
    }
  }

  .grid-container_benefits {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    justify-content: center;

    .benefit-item {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      border-radius: 0.5rem;
      justify-content: start;
      background-color: white;
      border: 1px dashed rgb(215, 215, 215);
      position: relative;
      align-items: left;
      padding: 1rem;

      img {
        width: 30px;
      }

      h3 {
        color: #333;
      }

      p {
        color: #333;
      }
    }
  }
}

@media (min-width: 640px) {
  .grid-container_benefits {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 768px) {
  .benefits-container {
    padding: 10% 10% 10% 10%;
    border-radius: 0 0 5rem 5rem;

    div.benefits-title-container {
      h2 {
        width: 48%;
      }

      p {
        width: 48%;
      }
    }

    .grid-container_benefits {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

@media (min-width: 1024px) {
  .benefits-container {
    padding: 10% 10% 10% 10%;

    div.benefits-title {
      h2 {
        width: 40%;
      }

      p {
        width: 30%;
      }
    }

    .grid-container_benefits {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}
