@use './../../scss/variables' as *;

button.dialogButton {
    background-color: whitesmoke !important;
    color: $orange !important;

    padding: 0.5rem 2rem;
    border-radius: 0.5rem;
    border: 1px solid $orange;
    
    flex: 1;
    cursor: pointer;


    &:hover {
      background-color: $orange !important;
      color: white !important;
    }
  }

  dialog {
    border: none;
    border-radius: 12px;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.2);
    width: 700px;
    padding: 20px;
    background-color: #f9f9f9;
    color: #333;
    position: relative;
    max-width: 90%;

    &::backdrop {
      background: rgba(0, 0, 0, 0.4); /* Dark overlay */
      backdrop-filter: blur(5px); /* Blurs the background */
    }
  }

  dialog[open] {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    flex-direction: column;

    button.closeDialog {
      background-color: grey !important;
      color: white !important;
      border: none;
      padding: 10px 15px;      
      border-radius: 5rem;
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;

      &:hover{
        background-color: lightgrey !important;
        color: grey !important;
      }
    }

    

    iframe{
      width: 100%;

      transform: scale(0.9); /* Example: Scale to 90% */


    }

}