@use "./../../scss/mixins";



.reviews-container {
  padding: 10%;
  display: flex;
  flex-direction: column;
  gap: 2rem;


 

  div.reviews-title-container{
    text-align: center;

    h2 {
      color: #333;
  
      
    }

    p{
      color: grey;
      
    }
  }

  .quotes-container {
    column-count: 1; // Single column on mobile
    column-gap: 1rem;
    row-gap: 1rem;

    .quote {
      display: inline-block; // Important to make quotes behave properly within columns
      width: 100%; // Ensures the quote fills the column width
      margin-bottom: 1rem;
      padding: 1rem;
      border-radius: 1rem;

      background-color: white;

      border: solid 1px lightgrey;

      p{
        color: grey;
      }

      b {
        color: #333;
      }

  

     

      .quote-profile_container{
        margin-top: 1rem;
        display: flex;
        gap: 1rem;
        align-items: center;

        img{
          width: 40px;
          height: 40px;
          border-radius: 5rem;
        }

        .quote-circle{
          display: flex;
          align-items: center;
          justify-content: center;

          min-width: 40px;
          height: 40px;

          border-radius: 5rem;


          p{
            font-size: 20px;
            color: whitesmoke;
          }
        }

        .quote-profile_text{
          display: flex;
          flex-direction: column;

          p{
            color: grey;
          }

          .quote-name {
            font-size: 18px;
            font-weight: 700;
            color: #333;
        }
        }

       

      }

     
    }
  }
}

/* Tablet and desktop: Two columns */
@media (min-width: 768px) {
  .reviews-container {
    .quotes-container {
      column-count: 2; // Two masonry-like columns
      column-gap: 1rem;
    }
  }
}
