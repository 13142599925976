@use "./../../scss/variables" as *;

// FAQ Container Styling
.faq-section {
    margin: 0;
    width: 100%;

    padding: 10%;

  
    h2 {
      text-align: center;
      color: #333;
      margin-bottom: 1.5rem;
    }
  }
  
  // FAQ Items
  .faq-items {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  
    .faq-item {
      background-color: white;

      padding: 1rem ;
      cursor: pointer;
      border-radius: 1rem;
      border: solid 1px transparent;
  
      &:hover {
        border: solid 1px $orange;

      }
  
      &.active {
        .faq-question {
          color: $orangeDark; // Apple's signature blue for active state
        }
  
        .faq-answer {
          max-height: 150px;
          opacity: 1;
          margin-top: 0.5rem;
        }
      }
  
      // FAQ Question Styling
      .faq-question {
        display: flex;
        justify-content: space-between;
        font-size: 1rem;
        font-weight: 500;
        color: #333;
        align-items: center;
      }
  
      // FAQ Answer Styling
      .faq-answer {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.4s ease, opacity 0.4s ease;
        opacity: 0;
        font-size: 0.95rem;
        color: #333;
      }
  
      // Toggle Icon Styling
      .toggle-icon {
        font-size: 1.5rem;
        color: $orange;
      }
    }
  }
  
  // Responsive Design
  @media (max-width: 600px) {

  
    .faq-items {
      .faq-item {
        .faq-question {
          font-size: 0.9rem;
        }
      }
    }
  }
  