div.pricing-container {
  display: flex;
  flex-direction: row;
  align-self: center;
  align-items: center;
  width: 100%;
  padding: 10%;
  gap: 1rem;
  border-radius: 2rem 2rem 0 0;
  flex-wrap: wrap;
  justify-content: center;


  background: radial-gradient(80% 50% at 50% 0%, #0099fe, #0d0f10 60%);
  box-shadow: 0px -10px 20px rgba(0, 0, 0, 0.20);


 
  div.title-description-container {
    width: 100%;
    align-items: center;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    h2 {
      color: white;
    }

    p {
      color: lightgrey;
    }
  }

  div.pricing_buttons {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;

    button {
      border: none;
      border-radius: 0.5rem;
      padding: 0.5rem 1rem;

      background-color: #ffffff35;
      color: white;
      font-weight: 500;

      &:hover {
        background-color: #ddebff !important;
        color: #3986ff !important;
      }
    }

    .active {
      background-color: #1763e5;
      color: white;
    }
  }

  div.pricing {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 3rem 2rem;
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 1rem;
    gap: 1rem;
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.1);

    flex-basis: 100%;

    span.pricing-option {
      background-color: rgba(255, 255, 255, 0.05);
      padding: 0.25rem 1rem;
      border-radius: 4rem;
      font-size: 14px;
      color: lightgrey;

      width: fit-content;
    }

   

    h3.pricing_title {
      color: white;
      text-align: left;
      font-size: 28px;
    }
    p.pricing_description {
      color: lightgrey;
    }

    p.pricing_price {
      color: white;
      font-size: 20px;
      font-weight: 500;

      b.pricing_notPrice {
        text-decoration: line-through !important;
        color: grey;
        font-size: 18px;
        font-weight: 300;
      }
    }

    hr {
      border: 1px solid rgba(128, 128, 128, 0.5);
    }

    ul {
      display: flex;
      flex-direction: column;
      text-decoration: none;
      list-style-type: none; /* Removes bullet points */
      padding: 0; /* Optional: Remove default padding */
      margin: 0; /* Optional: Remove default margin */
      gap: 1rem;

      li {
        display: flex;
        gap: 1rem;
        

        p {
          font-weight: 500;
          font-size: 14px;
        }
      }

      li.grey-feature {
        color: rgb(255, 230, 7);

        p {
          color: rgb(235, 230, 178);
        }
      }

      li.green-feature {
        color: #35f099;

        p {
          color: #d7f1e5;
        }
      }
    }
  }

  div.pricing.basic-option {
  }

  div.pricing.standard-option {
    border: 1px solid #1763e5;
    background-color: #1762e528;
  }

  p{
    color: grey;
  }
}

@media (min-width: 768px) {
  div.pricing-container {
    border-radius: 2rem 2rem 0 0;
    div.pricing {
      flex-basis: 47%;
    }
  }
}

@media (min-width: 1024px) {
  div.pricing-container {
    div.pricing {
      flex-basis: 30%;
    }
  }
}
