.goBackIcon-container{
    padding: 10% 5% 0 5%;

    margin-top: 6rem;
    background-color: whitesmoke;


    i{
        color: rgb(50, 50, 50);
        font-size: 32px;

        &:hover{
            color: grey;
            scale: 0.90;
        }
    }
}

@media (min-width: 768px){
    .goBackIcon-container{
        margin-top: 0rem;
    }
}

@media (min-width: 1024px){
    .goBackIcon-container{
        padding: 10% 5% 0 20%;

    }

}