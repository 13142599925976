.roast-container {
    width: 100%;
    margin: 0 auto;
    padding: 10rem 2rem;
    text-align: center;

    //inner shadow
    box-shadow: inset 0 0 50px 5px #0098fe78;
  
    h1 {
      font-size: 3rem;
      font-weight: 700;
      margin-bottom: 1.5rem;
      line-height: 1.2;
      padding: 0 25%;
    }
  
    p {
      font-size: 1.25rem;
      color: #666;
      max-width: 600px;
      margin: 0 auto 2rem;
      line-height: 1.6;
    }
  
    button {
      background: #000;
      color: white;
      border: none;
      padding: 1rem 2rem;
      border-radius: 8px;
      font-size: 1.125rem;
      cursor: pointer;
      transition: transform 0.2s ease;
  
      &:hover {
        transform: translateY(-2px);
      }
  
      span {
        opacity: 0.8;
        font-size: 0.9rem;
        margin-left: 0.5rem;
      }
    }

    p#limited-time-offer {
      font-size: 0.8rem;
      color: #666;
      margin-bottom: 2rem;
      margin-top: 0.5rem;
    }
  
    .social-proof {
      margin: 3rem 0;
      
      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin: 0 0.5rem;
        object-fit: cover;
        margin-right: -1rem;
        border: 2px solid #fff;
      }
  
      p {
        font-size: 1rem;
        color: #888;
        margin-top: 1rem;
      }
    }
  
    .roasted-gallery {
      margin-top: 4rem;
      
      img { //important to maintain width and aspect ration (specially in desktop)
        max-width: 720px;
        aspect-ratio: 16/9;
        height: auto;
        border-radius: 12px;
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
        transition: opacity 0.3s ease;

        object-fit: cover;
        object-position: top;
      }
    }
  
    @media (max-width: 768px) {
      padding: 2rem 1rem;

      h1 {
        font-size: 2rem;
        padding: 0 5%;
      }

      p {
        font-size: 1rem;
        max-width: 100%;
      }

      button {
        width: 100%;
        max-width: 300px;
      }

      .social-proof {
        margin: 2rem 0;
        
        img {
          width: 32px;
          height: 32px;
        }
      }

      .roasted-gallery {
        margin-top: 2rem;
        
        img {
          max-width: 100%;
        }
      }
    }
  }