@use "./../../scss/mixins";
@use "./../../scss/variables" as *;

.hero-container {
  min-height: 95vh;

  display: flex;
  flex-direction: column;
  margin-top: 6rem;
  justify-content: center;



  div.hero {

    padding: 10% 5%;

    flex: 1;
    overflow: hidden; /* Ensure the pseudo-element stays contained */
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    align-items: center;
    

    .profile-hero-container {
      width: 100%;
      align-items: center;
      justify-content: start;
      display: flex;
      gap: 1rem;

      img.profile-img_hero {
        max-width: 50px;
        max-height: 50px;
        border-radius: 0.5rem;
        transform: rotate(-3deg); /* Use 'transform' for rotation */
        cursor: pointer;

        &:hover {
          transform: rotate(-10deg);
        }
      }

      .profile-hero_text {
        p {
          font-size: 14px;
          width: 100%;
        }
        b {
          color: #8C8C8C;
        }
      }
    }

    .h1-container_hero {
      width: 100%;

      h1 {
        text-align: start;
        line-height: 1.1; /* or use a pixel/percentage value */
        color: #333;
        font-family: 'Made Mirage';
        font-weight: 700;

        span {
          display: inline-block; /* Ensure the span behaves as a single block */
          white-space: nowrap; /* Prevent text wrapping within the span */

          .char {
            opacity: 0;
            transform: translateY(20px);
            display: inline-block;
            animation: fadeInUp 0.5s forwards;
            will-change: transform, opacity;
            color: #2998f4;
          }
        }
      }
    }

    p {
      font-weight: 400;
      text-align: start;
      color: #8C8C8C;
      font-size: 18px;
      width: 80%;
      align-self: start;
    }

    div.buttons-container_hero {
      width: 100%;
      display: flex;
      gap: 1rem;
      align-items: center;
      justify-content: start;

      button {
        border: 1px solid $orange;
      }

      button.book-call_hero {
        padding: 0.5rem 2rem;
        border-radius: 0.5rem;
        border: none;
        flex: 1;
        background-color: $orange;
        color: white;

        &:hover {
          background-color: $orangeDark;
        }
      }
    }

    button {
      background-color: transparent;
      border: none;
      color: grey;
      font-size: 16px;

      font-weight: 500;

      &:hover {
        text-decoration: none;
        color: #3986ff;
      }
    }

    #hero-legends {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: start;

      img {
        height: 30px;
        border-radius: 5rem;
        margin-right: -5px;
        border: 2px solid transparent;
      }

      p {
        margin-left: 1rem;
        font-size: 14px;
        width: fit-content;
      }
    }

    //###### SHOWCASE ######
    div.hero-showcase {
      z-index: 0;
      width: 100%;
      display: flex;
      flex-direction: row;
      gap: 1rem;
      margin-top: 1rem;

      position: absolute;
      bottom: 0;
      left: 0;

      img:nth-child(1) {
        transform: rotate(-5deg);
      }

      img:nth-child(2)  {
        transform: rotate(5deg);
      }

      img:nth-child(3)  {
        transform: rotate(0deg);
      } 

      img:nth-child(4)  {
        transform: rotate(-5deg);
      } 

      img{
        width: 25%;
        height: 120px;
        object-fit: cover;
        border-radius: 0.5rem;
        cursor: pointer;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
        object-position: top;

        &:hover {
          transform: translateX(-10px) scale(0.9);
          box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
        }
      }
    }
  }



 
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* HERO TITLE STYLES FOR LARGER SCREENS */
@media (min-width: 768px) {
  .hero-container {
    margin-top: 0rem; /* Add margin to move the hero down for navbar */

    div.hero {
      padding: 10%;
    }
  }
}

@media (min-width: 1024px) {

  .hero-container {
    min-height: 85vh;

    gap: 5rem;
    flex-direction: row;

    div.hero {
      padding: 10% 22%;
      align-items: center;

      h1 {
        font-size: 72px;
        text-align: center !important;
        padding: 0 4%;
        align-self: center;
      }

      p {
        text-align: center;
        align-self: center;
      }

      div.profile-hero-container {
        justify-content: center;
      }

      div.buttons-container_hero {
        justify-content: center;
      }

      div#hero-legends {
        justify-content: center;
      }

      div.hero-showcase {
        img {
          border-radius: 2rem 2rem 0 0;
        }
      }
    }

    div.hero-right-img {
      max-width: 50%;

      img {
        display: block;
      }
    }
  }
}
