.case-study-main-container{
  padding: 3rem 5%;
  background-color: whitesmoke;


  h2.case-study-title{
    text-align: left;
  
    color: #333;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    
    opacity: 0.8;
    z-index: -1;
    transition: opacity 0.5s ease;

  }

  h3,
  h4 {
    color: rgb(50, 50, 50);

  }
  
  p{
    color: rgb(50, 50, 50);

  }
  
  b{
    color: rgb(50, 50, 50);

  
  }
}

@media (min-width: 1024px) {
  .case-study-main-container{

    padding: 1rem 20%;
  }

}
  

  
  
  
  .two-column-metadata {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 2.5% 0;
    margin-top: 16px;
    gap: 16px;
  }
  
  .quote-case-study {
    border-left: 3px solid rgb(50, 50, 50);
    padding-left: 12px;
    color: rgb(50, 50, 50);

    font-weight: bold;
  }
  
  /* CASE STUDIES */
  
  .case-studies-content button {
    color: #333333;;
    background-color: rgb(203, 203, 0);
    border-radius: 4px;
    padding: 0 8px;
  }
  
  .block {
    margin: 16px 0;
  }
  