@use "./../../scss/mixins";

div.services-container{
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 5% 10% 10% 10%;
  border-radius: 2rem 2rem 0 0;
  position: relative;
  background-color: #0D0F10;



  div.services-title-container{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    img{
      height: 200px;
    }

    h2, p{
      text-align: center;
    }

    h2 {

      @include mixins.white-gradient_text();

    }

    p{
      color: lightgrey;
      font-weight: 500;
    }
  }
  

  div.services-item-container{

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;

    div.service-item{
      flex-basis: 100%;
      padding: 2rem 2rem 0 2rem;
      border-radius: 1rem;
      border: solid 1px rgb(36, 36, 36);

      background-color: #101111;

      background: linear-gradient(to bottom, #101111 0%, #000000 100%);


      display: flex;
      flex-direction: column;
      gap: 1rem;
      align-items: start;
      justify-content: space-around;

      button.service-item-button{
        background-color: #ffffff1c;
        position: absolute;
        bottom: 1rem;
        right: 1rem;
        border-radius: 5rem;
        color: rgba(255, 255, 255, 0.491); 
        font-size: 1.5rem;
        width: 3rem;
        height: 3rem;
        z-index: 10;
        border: 1px solid rgba(255, 255, 255, 0.07);
        backdrop-filter: blur(5px);

        &:hover{
          background-color: #ffffff8f;
          color: whitesmoke;
          scale: 1.05;
        }
      }

      img{
        height: 200px;
        align-self: center;
      }


     

      div{
        display: flex;
        align-items: center;
        gap: 0.5rem;
        flex-direction: column;
        text-align: left;

        p{
          font-size: 16px;
          color: lightgrey;
        }
       

        h3{
     
          width: 100%;
          color: whitesmoke;

          font-size: 18px;

          font-family: 'Inter', sans-serif !important;
          text-align: left;
        }
      }

      position: relative;
      overflow: hidden;
    }
  }

  div.services-more{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;

    h3{
      color: grey;
      font-family: 'Inter', sans-serif !important;
    }

    div.services-more-items{
      display: flex;
      flex-direction: row;
      gap: 1rem;
      width: 100%;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;

      p{
        padding: 0.5rem 1rem;
        border-radius: 1rem;
        background-color: #ffffff10;
        color: grey;
      }
    }

   
  }
}

@media (min-width: 768px) {

  div.services-container{
    border-radius: 5rem 5rem 0 0;

    div.services-item-container{
      div.service-item{
        flex-basis: 48%;
        max-width: 48%;
      }
    }
  }

}

@media (min-width: 1024px) {

  div.services-container{
    div.services-item-container{
      div.service-item{
        flex-basis: 31%;
        max-width: 31%;
      }
    }

    div.services-more{
      div.services-more-items{
        width: 50%;
      }
    }
  }

}
