@use "./../../scss/variables" as *;
@use "./../../scss/mixins";




.tabs-main-container {
  padding: 5%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2rem;
    background-color: #0d0f10;

  border-radius: 0;
  box-shadow: 10px 15px 25px rgba(0, 0, 0, 0.4); /* Apple shadow */


 
  img{
    height: 200px;
    margin-bottom: -10%;
  }

  h2 {
    text-align: center;
    @include mixins.white-gradient_text();
  }

  .case-studies-categories-container {
    display: flex;
    justify-content: center;
    gap: 1rem;
    flex-wrap: wrap;

    button {
      padding: 0.5rem 1rem;
      border-radius: 0.5rem;
      background-color: #ffffff35;
      border: none;
      color: white;
      font-size: 14px;

      &:hover {
        background-color: $lightYellow;
        color: $orange;
      }
    }

    button.active {
      background-color: $orange;
      color: white;
    }
  }
}

/* Container for the case study cards */
.case-studies-card-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center; /* Align cards to the start */
  width: 100%;
  align-items: center;
  gap: 1rem;
  padding: 0 5% 5% 5%;
  flex-wrap: wrap;

  .case-studies-card {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    height: 100%;
    width: 100%;
    
    background-color: #101111;

    border: solid 1px rgb(36, 36, 36);
    border-radius: 1rem;
    cursor: pointer;
    gap: 1rem;


    img {
      width: 100%;
      max-height: 150px;
      object-fit: cover;
      transition: box-shadow 0.3s, transform 0.3s;
      transition: scale ease-in-out 0.3s;
      border-radius: 0.7rem 0.7rem 0 0;
      margin-bottom: 0.5rem;
      border: 1px solid rgba(255, 255, 255, 0.07); 
    }

    div.case-studies-card-overlay {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      padding: 0 1rem 1rem 1rem;

      h3 {
        font-family: "Inter", sans-serif !important;
        width: 100%;
        color: rgb(255, 255, 255);
        font-weight: 600;
        font-size: 16px;
      }

      div.case-studies-card-tags {
        display: flex;
        flex-wrap: wrap;
        gap: 0.5rem;

        p {
          font-size: 12px;
          color: lightgrey;
          background-color: #2a2a2a;
          padding: 0.2rem 0.5rem;
          border-radius: 0.5rem;
        }
      }
    }

    
  }
}

/* Tablet and desktop: Adjust layout for larger screens */
@media (min-width: 768px) {
  .case-studies-card-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    
    .case-studies-card {
      min-width: unset;
    }
  }
}

@media (min-width: 1024px) {
  .case-studies-card-container {

    .case-studies-card {

      img {
        max-height: 200px;
      }

    }
  }
}

@media (min-width: 1024px) {
  .case-studies-card-container {
    padding: 0 10% 5% 10%;

    .case-studies-card {
      img {
        max-height: 250px;
      }
    }
  }
}

.block {
  img {
    max-width: 100%;
  }
}
