$orange: #e6472b;       // Orange color
$orangeDark: #ba3821;   // Dark orange
$lightYellow: #FAF9CC;       // Yellow color

// _variables.scss (This should be in a separate file for maintainability)
$font-family-primary: apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
$font-family-secondary: 'Made Mirage', sans-serif;
$bg-color: #F8F8F8;
$primary-text-color: grey;
$heading-line-height: 1.2;
$default-font-size: 16px;
$heading-font-size-large: 64px;
$heading-font-size-medium: 42px;
$heading-font-size-small: 36px;