 /* FOOTER */
  
 footer {
    padding: 5%;
    justify-content: center;
    display: flex;
    text-align: center;
    color: lightgrey;
    border-radius: 2rem 2rem 0 0;
    flex-wrap: wrap;
    gap: 1rem;

    background: linear-gradient(to bottom, #202020, #08090a);

    box-shadow: 0px -10px 20px rgba(0, 0, 0, 0.20);

    div{
      flex-basis: 100%;
      text-align: left;
      font-size: 14px;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      justify-content: center;
      align-items: center;

      h3{
        color: white;
      }

      img{
        height: 100px;
        width: fit-content;
        aspect-ratio: 1/1;
        max-width: 100px;
      }

     
    }

    div#bestcss-footer{
      
    }

    div#roco-footer{
      p{
        text-align: center;
      }
      img{
        border-radius: 8px;
      }
    }

    p.footer-data{
      margin-top: 3rem;
      width: 100%;
      font-size: 12px;
      color: grey;
    }

  }

  @media (min-width: 768px) {

    footer{
      border-radius: 5rem 5rem 0 0;
      div{
        flex-basis: 24%;

      }
    }
    
  }
  