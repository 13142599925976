@use 'variables' as *;

@mixin white-gradient_text($deg: 45deg, $color1: white, $color2: #71717A) {
    background: linear-gradient($deg, $color1, $color2);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  

/* ######### Magic Cards ######### */

.magic-card{
  position: relative;

  &::before{
    background: radial-gradient(
      800px circle at var(--mouse-x) var(--mouse-y), 
      rgba(255, 255, 255, 0.07),
      transparent 40%
    );
    border-radius: inherit;
    content: "";
    height: 100%;
    left: 0px;
    opacity: 0;
    position: absolute;
    top: 0px;
    transition: opacity 500ms;
    width: 100%;
    z-index: 3;
  
  }

  &:hover::before {
    opacity: 1;
  }
}
  

/* #### other ###### */



html {
  background-color: $bg-color;
  scroll-behavior: smooth;
  overflow-x: hidden; /* Prevent horizontal scroll */



}

/* Global styles */
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: $font-family-primary;
  font-optical-sizing: auto;
}

img {
  user-select: none; /* Disables text selection */
  pointer-events: none; /* Prevents interaction, including selection and clicking */
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  transition: all ease-in-out 0.2s;
}

/* Button styles */
button {
  cursor: pointer;
  font-size: $default-font-size;
  font-weight: 500;
}

/* Heading styles */
h1,
h2,
h3 {
  font-family: $font-family-secondary, sans-serif;
  font-weight: 600;
}

h1 {
  font-size: $heading-font-size-large;
  line-height: $heading-line-height;

  @media (max-width: 768px) {
    font-size: $heading-font-size-medium;
  }
}

h2 {
  line-height: $heading-line-height;
  font-size: $heading-font-size-medium;

  @media (max-width: 1024px) {
    font-size: $heading-font-size-small;
  }
}

h3 {
  line-height: $heading-line-height;
  font-size: 20px;
}

h4 {
  line-height: $heading-line-height;
  font-size: 16px;
  font-family: $font-family-primary;
  font-weight: bold;
}

/* Text elements */
p,
b,
button {
  font-family: $font-family-primary;
  font-weight: 400;
}

figcaption {
  color: $primary-text-color;
  font-size: 14px;
  font-family: $font-family-primary;
}
