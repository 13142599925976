nav {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 1rem 10%;
  z-index: 999;
  background-color: rgba(255, 255, 255, 0.177);
  justify-content: space-between;
  backdrop-filter: blur(10px);


  position: fixed;
  top: 0;
  transition: transform 0.3s ease-in-out;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  gap: 1rem;

  &.nav-hidden {
    transform: translateY(-100%);
  }

  &.nav-visible {
    transform: translateY(0);
  }

  div.logo-container {
    width: fit-content;
    display: flex;
    align-items: center;

    a {
      height: 100%;
      cursor: pointer;

      img {
        height: 40px;
      }
    }
  }

  div.menu-container{

    ul{
        display: flex;
        gap: 1rem;
        list-style-type: none;
        padding: 0;            
        margin: 0;            

        li{
            text-decoration: none;
            color: white;
            padding: 0.5rem 1rem;
            border-radius: 0.5rem;

            font-weight: 500;

            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;

            a{
              text-decoration: none;
              color: inherit;
            }
        }

        li:nth-child(1){
          background-color: whitesmoke;
          color: #e6472b;
          border: 1px solid #e6472b;

          &:hover{
            background-color: #e6472b;
            color: white;
          }
        }

        li:nth-child(2){
          background-color: #e6472b;
          color: white;


          &:hover{
            background-color: #ba3821;
          }
        }
    }
  }

}


@media (min-width: 768px) {
  nav{
    padding: 0.5rem 5%;
  }
}

@media (min-width: 768px) {
  nav{
    padding: 0.5rem 10%;
  }
}